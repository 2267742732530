
import { Contact, ContactProfileEnum } from '@gid/models';
import { Vue, Component, Prop } from 'vue-property-decorator';

type UserProp = {
  isPartner: boolean;
  name: string;
  email: string;
  emailForOrders: string;
  phone: string;
  mobilePhone: string;
  companyName: string;
  productDeliveryAddress: Address;
  productDeliveryFirstName: string;
  productDeliveryLastName: string;
  deliveryAddressCompanyName: string;
  shippingAddress: Address;
  contacts: Contact[];
};

type Address = {
  street: string;
  postalCode: string;
  city: string;
  additionalInfo: string;
};

@Component
export default class UserInfo extends Vue {
  @Prop(Object) readonly user!: UserProp;

  userFields = [
    { label: 'Email', field: 'email', protocol: 'mailto' },
    { label: 'Email', field: 'emailForOrders', protocol: 'mailto' },
    { label: 'Phone', field: 'phone', protocol: 'tel' },
    { label: 'Mobile', field: 'mobilePhone', protocol: 'tel' },
  ];

  get useUserNames() {
    const userContacts =
      this.user.contacts?.filter(
        (contact) => !contact.email?.includes('getitdone'),
      ) || [];

    if (
      this.user.productDeliveryFirstName &&
      this.user.productDeliveryLastName
    ) {
      return `${this.user.productDeliveryFirstName} ${this.user.productDeliveryLastName}`;
    } else if (
      userContacts.length === 1 &&
      userContacts[0].firstName &&
      userContacts[0].lastName
    ) {
      return `${userContacts[0].firstName} ${userContacts[0].lastName}`;
    } else if (userContacts.length > 0) {
      let contact = userContacts.find((contact) =>
        contact.profiles
          .map((p) => p.profile)
          ?.includes(ContactProfileEnum.COMPANY_OWNER),
      );
      if (contact?.firstName && contact?.lastName) {
        return `${contact.firstName} ${contact.lastName}`;
      }
      contact = userContacts.find(
        (contact) =>
          contact.profiles
            .map((p) => p.profile)
            ?.includes(ContactProfileEnum.ADMIN) && this.user.isPartner,
      );
      if (contact?.firstName && contact?.lastName) {
        return `${contact.firstName} ${contact.lastName}`;
      }
    }
    return null;
  }

  get userFieldsMap() {
    return this.userFields
      .filter(({ field }) => !!this.user[field])
      .map((item) => ({
        link: `${item.protocol}:${this.user[item.field]}`,
        label: this.$t(`job.product-order.${item.label.toLowerCase()}`),
        value: this.user[item.field],
      }));
  }
}
